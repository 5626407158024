<template>

	<div class="user">
		<h4>我的问答</h4>

		<div class="coulist">
			<div class="couitem" v-for="(item,i) in list" :key="i" >
			
				<div class="c2">
                    <div>
                        <p><strong>{{item.goods_name}}</strong></p>
                        <p> {{item.create_time}}</p>
                    </div>
					<p>{{item.question}}</p>
				</div>
                <p class="c3" v-if="item.status==1">
                    <span>客服回复:</span>
                    {{item.answer}}
                </p>
			</div>

		</div> 
	</div>

</template>

<script>
import { userQuestionlistAPI} from "@/api/user";
export default {

  data () {
    return {
      sign: 1,
      list:[]

    }
  },
  created () {
      this.getList()
  },
  methods: {
    tourl () {
      this.$router.push({ path: '/user/orderInfo' })
    },
    getList(n){
		this.sign=n
		  var _this=this
		  userQuestionlistAPI().then(res=>{
			  _this.list=res.cateList
		  })
	  }
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
        min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			
		}

		.coulist{
           .couitem{
            
               padding:10px 0;
           }
           
           .c2{
               line-height: 30px;
               div{
                   display: flex;
                    justify-content: space-between;
				   align-items: center;
                   p{
                       margin-right: 25px;
                       font-size: 12px;
                       color: #999999;
                       strong{
                           font-size: 16px;
                       }
                   }
               }
               >p{
                   font-size: 14px;
                  line-height: 30px;
               }
           }
            .c3{
                line-height: 44px;
                opacity: 1;
                background: #f9f9f9;
                border-radius: 4px;
                padding:3px 10px;
                span{
                    color: #FF7C00;
                    margin-right: 5px;
                    border-radius: 3px;
                }
            }
			

		}
	}

</style>
